"use client";
// Providers are in use client becuase -> https://nextjs.org/docs/getting-started/react-essentials#context

import { SessionProvider } from "next-auth/react";
import { ThemeProvider, useTheme } from "next-themes";
import type { FC, ReactNode } from "react";
import ReduxProvider from "../../reduxState/reduxProvider";
import { ReactQueryProvider } from "@/utils/react-query-provider";
import { RouteChangeConfirmationProvider } from "@/utils/RouteChangeConfirmationProvider";
//import { ThemeProvider as PrimerThemeProvider, BaseStyles as PrimerBaseStyles } from "@primer/react";
//			<PrimerThemeProvider>
//				<PrimerBaseStyles>

interface ProvidersProps {
	children: ReactNode;
}
//Providers typically use react context to provide data to their children.
//https://react.dev/learn/passing-data-deeply-with-context
const Providers: FC<ProvidersProps> = ({ children }) => {
	const { setTheme, theme } = useTheme();

	return (
		<ThemeProvider attribute="class" defaultTheme="dark" forcedTheme={theme}>
			<ReduxProvider>
				<ReactQueryProvider>
					{/*<RouteChangeConfirmationProvider>*/}
					<SessionProvider>{children}</SessionProvider>
				</ReactQueryProvider>
			</ReduxProvider>
		</ThemeProvider>
	);
};

export default Providers;
