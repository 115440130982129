import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

// Define the structure of each banner message
export interface BannerMessage {
  title: string;
  message: string;
  action?: () => void;
}

// Define types the state
export type DataState = {
  aiFillRequestStarted: number | null;
  handledAIFillRequestStarted: number | null;
  aiFillRequestCompleted: number | null;
};

const aiDataSlice = createSlice({
  name: "data", // Name of the slice
  initialState: {
    aiFillRequestStarted: null,
    handledAIFillRequestStarted: null,
    aiFillRequestCompleted: null,
  },

  reducers: {
    // This is an example of [type] syntax in a reducer function
    setAIRequestStarted: (state, action) => {
      state.aiFillRequestStarted = action.payload;
    },
    setHandledAIFillRequestStarted(state, action) {
      state.handledAIFillRequestStarted = action.payload;
    },
    setAIRequestCompleted: (state, action) => {
      state.aiFillRequestCompleted = action.payload;
    },
    resetUISlice: (state) => {
      state.aiFillRequestStarted = null;
      state.aiFillRequestCompleted = null;
      state.handledAIFillRequestStarted = null;
    },
  },
});

export const { setAIRequestStarted, setHandledAIFillRequestStarted, setAIRequestCompleted, resetUISlice } = aiDataSlice.actions;
export default aiDataSlice.reducer;
